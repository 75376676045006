<template>
  <div class="image-list-view previewImgBox" v-if="fileList.length!=0">
   <!-- <ul>
      <li v-for="(item, key) in fileList" :key="key">
        <img v-if="!/\.pdf$/.test(item.fileName)" :src="/\.pdf$/.test(item.fileName) ? pdfDefaultImg : item.src" alt="" style="max-width: 120px;width: 70px;height: 70px;">
        <canvas v-else  :class="'the-canvas'+string" >
        </canvas>
		<span style="margin-left: 24px; line-height: 72px;">File name: </span>
        <span style="margin-left: 30px; line-height: 72px;">{{ item.fileName }}</span>
        <p style="float: right; margin-right: 20px; height: 72px; line-height: 72px;">
          <i class="el-icon-close" style="font-size: 24px; cursor: pointer;" @click="onDelFileItem(0)"></i>
        </p>
      </li>
    </ul> -->
	<div  class="previewImg">
		<i class="el-icon-close" style="font-size: 15px; cursor: pointer;" @click="onDelFileItem(0)"></i>
	</div>
	  <img v-if="!/\.pdf$/.test(fileList[0].src)" :src="fileList.length>0?fileList[0].src:''" style='width: 240px;' alt="">
	  <canvas v-else  :class="isEdit?'isEdit':'the-canvas'+string"  ref='img'>
	  </canvas>
  </div>
</template>

<script>
	const PDFJS = require('pdfjs-dist');
	PDFJS.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.entry');
	
export default {
  components: {},
  props: {
    fileList: {
      type: Array,
      default: () => []
    },
	isEdit :{
		type:Boolean,
		default:false
	}
  },
  data() {
    return {
		pages: [],
		num:0,
      pdfDefaultImg: require('./pdf-default.jpg'),
	  string:Math.random(),
	  is_loading:false,
    };
  },
  watch:{
	  fileList:{
		  deep:true,
		   handler(res) {
			   this.$nextTick(() => {
				   if(this.fileList.length==0){
				   	return 
				   }
			let item = this.fileList[this.fileList.length-1]
			console.log(item)
			if(/\.pdf$/.test(item.fileName)){
			this._loadFile(item.src);
			}else{
				this.$emit('loading')
			}
			   });
		   }
	  }
  },
  methods: {
    onDelFileItem(index){
      this.fileList.splice(index, 1)
    },
	_renderPage (num) {
	    this.pdfDoc.getPage(1).then((page) => {
			let canvas 
			if(this.isEdit){
				 canvas = document.getElementsByClassName(`isEdit`)[0]
			}else{
				 canvas = document.getElementsByClassName(`the-canvas${this.string}`)[document.getElementsByClassName(`the-canvas${this.string}`).length-1]
			}
			var vp = page.getViewport({scale: 1});
	        let ctx = canvas.getContext('2d')
	        let dpr = window.devicePixelRatio || 1
	        let bsr = ctx.webkitBackingStorePixelRatio ||
	            ctx.mozBackingStorePixelRatio ||
	            ctx.msBackingStorePixelRatio ||
	            ctx.oBackingStorePixelRatio ||
	            ctx.backingStorePixelRatio || 1
	        let ratio = dpr / bsr
	        let viewport = page.getViewport({scale: window.innerWidth / vp.width});
	        canvas.width = viewport.width * ratio
	        canvas.height = viewport.height * ratio
	        canvas.style.width = 240 + 'px'
			canvas.style.height = 240 + 'px'
	        ctx.setTransform(ratio, 0, 0, ratio, 0, 0)
	        let renderContext = {
	            canvasContext: ctx,
	            viewport: viewport
	        }
	        page.render(renderContext)
			this.is_loading=false
	    })
	},
	_loadFile(url) {
	    PDFJS.getDocument(url).promise.then((pdf) => {
			
	        this.pdfDoc = pdf
	        this.pages = this.pdfDoc.numPages
	        this.$nextTick(() => {
				if(this.is_loading){
					return false
				}else{
					this.num+=1
					this._renderPage(this.num);
					this.is_loading=true
					this.$emit('loading')
				}
	        });
	    })
		.catch(()=>{
			this._loadFile(url)
		})
	}
  },
  created() {
	  if(this.fileList.length==1){
	  	let item = this.fileList[this.fileList.length-1]
	  	if(/\.pdf$/.test(item.src)){
	  	this._loadFile(item.src);
	  	}
	  }  
  },
  mounted(){
  }
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
li {
  margin-bottom: 8px;
  height: 72px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  img {
    height: 100%;
    vertical-align: top;
  }
}
 /deep/.previewImgBox{
	position: relative;
	width: 240px;
	
 }
 .previewImg{
		position: absolute;
		    top: 10px;
		    right: 10px;
		    color: white;
		    background-color: rgba(0,0,0,0.6);
		    border-radius: 50%;
		    display: flex;
		    padding: 3px;
	}
</style>