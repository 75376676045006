<!-- commbook -->
<template>
  <div class="recipients" v-loading='loading' >
    <base-button type="primary" v-if="isSelect" @click="selectStudentsDialogVisible = true">Select students</base-button>
    <div class="selected-student-list" v-if="isSelect">
        <p v-for="(item, key) in chooseStudentObj" :key="key" v-show="item.count && item.studentList.length">
          <head-img
            class="user-box"
            line-height="28"
            :firstName="item.studentList.length>0?item.studentList[0].className:''"
            @click="() => {}"
			:lastName="item.studentList.length>0?item.studentList[0].className.split(' ').length>1?item.studentList[0].className.split(' ')[1]:'':'' "
			:style="{ backgroundColor: item.themeColor }"
            style="display: inline-block; margin-right: 10px; vertical-align: top;"
          ></head-img>
          <span v-for="(sItem, key) in item.studentList" :key="key">
            {{ sItem.firstName }} {{ sItem.lastName }}
            <i class="el-icon-error" style="cursor: pointer; color: #cecece" @click="onDelStudent(item.studentList, key)"></i>
          </span>
        </p>
      </div>
    <div>
      <div style="overflow: hidden; margin-bottom: 10px">
        <md-field style="float: left; width: 48%; background-color: #f8f8f8;">
          <label for="movie">Topic</label>
          <md-select v-model="paramsDetail.topic" name="movie" id="movie">
            <md-option :value="item.value" v-for="(item, key) in topicList" :key="key">{{ item.value }}</md-option>
          </md-select>
        </md-field>
		<gg-input
		  v-model="paramsDetail.title"
		  placeholder="Title"
		  :error="errorTitle"
		  style="float: right; width: 48%;background-color: #f8f8f8;"
		/>
      </div>
	  <div>
		  <gg-input
		    v-model="paramsDetail.message"
		    placeholder="Add message"
		    :error="errorMessage"
		    style="background-color: #f8f8f8;"
			filedType='textarea'
		  />
	  </div>
    <!-- <md-field style="background-color: #f8f8f8;">
        <label>Add message</label>
        <md-textarea v-model="paramsDetail.message"></md-textarea>
      </md-field> -->
      <imageListPreview :fileList="fileList" @loading='changeloading'/>
	<!--  <div v-if="fileList.length>0"  class="previewImgBox">
		  
		  <div  class="previewImg">
			   <i class="el-icon-close" style="font-size: 15px; cursor: pointer;" @click="removeFileList()"></i>
		  </div>
		  
		    <img :src="fileList.length>0?fileList[0].src:''" style='width: 240px;' alt="">
	  </div> -->
	
      <div style="overflow: hidden; padding-top: 20px;">
		  
		  <el-upload
		    style="display: inline-block;"
		    :auto-upload="false"
		    action=""
		    accept="image/jpeg,image/png,application/pdf"
		    :show-file-list="false"
		    :on-change="(file, fileList) => {onUploadFileChange(file, fileList)}"
			:before-upload="beforeUpload"
			:disabled="fileList.length!=0"
		  >
		    <!-- <i
		      class="el-icon-link"
		      style="line-height: 32px; cursor: pointer; vertical-align: middle; color: #333; font-size: 20px; margin-right: 6px;"
		    ></i> -->
		  		<!-- <span  style="line-height: 32px; cursor: pointer; vertical-align: middle; color: #333; font-size: 20px; margin-right: 6px;" class="icon iconfont iconattach_file-web"></span> -->
		  <!-- <baseButton>Cancel</baseButton> -->
		   <baseButton :disabled="fileList.length!=0" style="margin-top: 8px;" type="primary" class='contained'><span class="icon iconfont iconattach_file-web"></span>Add</baseButton>
		  </el-upload>
       
		<!-- <el-button class="add-file-btn" size="small" type="primary">
		  <img class="add-file-icon" src="@/assets/images/icons/attach_file_bai.svg" />
		  Add
		</el-button> -->
        <div style="float: right">
          <base-button @click="onCancel">Cancel</base-button>
          <base-button :title="(!(paramsDetail.message && paramsDetail.title  && paramsDetail.topic) || (isSelect && JSON.stringify(chooseStudentObj)=='{}') || errorTitle.show || errorMessage.show) ? 'Cannot post without students,topic and message':''"  type="primary" @click="onPost" 
		  :disabled="!(paramsDetail.message && paramsDetail.title  && paramsDetail.topic) || (isSelect && JSON.stringify(chooseStudentObj)=='{}') || errorTitle.show || errorMessage.show" >Post</base-button>
		</div>
      </div>
    </div>
    <!-- dialog -->
    <img-cut-modal
      :visible.sync="isImgCutShow"
      v-if="isImgCutShow"
      :acceptPdfFile='true'
      @_uploadCallback="_uploadCallback"
      @_uploadPdfCallback="_uploadPdfCallback"
	  :isCut='true'
    />
    <selectStudentsDialog
      :dialogVisible="selectStudentsDialogVisible"
      :studentListObj="studentListObj"
      :selectedStudentObj="chooseStudentObj"
      @cancel="selectStudentsDialogVisible = false"
      @save="onSelectStudents"
    />
    <Snackbar
      :visible.sync="snackbar.isShow"
      :content="snackbar.content"
    />
  </div>
</template>

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
import imageListPreview from '@/components/private-components/ImageListPreview/index1.vue'
import selectStudentsDialog from '../dialog-modules/select-students-dialog.vue'
export default {
	name:'abc',
  components: {
    imageListPreview,
    selectStudentsDialog
  },
  props: {
	  isShowDialog:{
		  type:Boolean,
		  default:false
	  },
    isSelect: {
      type: Boolean,
      default: false,
    },
    studentListObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    studentId: {
      tyep: String,
      default: ''
    }
  },
  
  data() {
    return {
      topicList: [],
      isImgCutShow: false,
      chooseStudentObj: {},
      selectStudentsDialogVisible: false,
      fileList: [],
      paramsDetail: {
        topic: '',
        title: '',
        message: ''
      },
      snackbar: {
        isShow: false,
        content: "",
      },
	  loading:false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
	errorTitle(){
	      let nameVal = this.paramsDetail.title
	      let isPass = nameVal.length < 40;
	      return {
	        message: "Character limit of 40",
	        show: !isPass,
	      };
	    },
		errorMessage(){
		      let nameVal = this.paramsDetail.message
		      let isPass = nameVal.length < 1000;
		      return {
		        message: "Character limit of 1000",
		        show: !isPass,
		      };
		    }
  },
  mounted(){
    this.queryTopicList()
  },
  methods: {
	  changeloading(){
		this.loading=false  
	  },
	  beforeUpload(file) {
		  console.log(file)
	  				
	  				
	  			},
	  onUploadFileChange(file, fileList){
		  
		  const isLt2M = file.size / 1024 / 1024 < 25     //这里做文件大小限制
		   console.log(isLt2M)
		  if(!isLt2M) {
		  	this.$message({
		  		message: 'Upload files should not exceed 25MB',
		  		type: 'warning'
		  	});
		  	return  isLt2M
		  }
		  this.loading=true
	    if (file) {
	      let fileName = file.name
	      let formData = new FormData();
	      formData.append("file", file);
	      formData.append("multipartFile", file.raw);
	      Ajax.post(
	        // "/media/public/file/upload",
	        "/media/public/file/uploadExt",
	        formData,
	        {
	          headers: { "Content-Type": "multipart/form-data" },
	        }
	      )
	      .then((res) => {
	        if (res.code === "0000") {
	          let imgSrc = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
	          this.fileList.push({
	            fileName,
	            src: imgSrc,
	            id: res.data.requestId
	          })
	  		
	        }
	      })
	      .catch((err) => {
			  this.loading=false
	      });
	    }
	  },
	  removeFileList(){
		this.fileList=[]
	  },
    _uploadCallback(formData, fileData) {
      let fileName = fileData.name
      formData.append("multipartFile", fileData.raw);
      this.isImgCutShow = false;
      Ajax.post("/media/public/file/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          let imgSrc = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
          this.fileList.push({
            fileName,
            src: imgSrc,
            id: res.data.requestId
          })
        })
        .catch(() => {
        });
    },
    _uploadPdfCallback(file) {
      let fileName = file.name
      let formData = new FormData();
      formData.append("file", file);
      formData.append("multipartFile", file.raw);
      this.isImgCutShow = false;
      Ajax.post("/media/public/file/uploadExt", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        let imgSrc = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
        this.fileList.push({
          fileName,
          src: imgSrc,
          id: res.data.requestId
        })
      })
      .catch(() => {
      });
    },
    onSelectStudents(studentList){
      this.chooseStudentObj = studentList
	  console.log(this.chooseStudentObj)
      this.selectStudentsDialogVisible = false
    },
    onPost(){
      let studentIdList = []
      let fileList = []
      if (this.studentId) {
        studentIdList = [this.studentId]
      } else {
        for(let key in this.chooseStudentObj) {
          this.chooseStudentObj[key].studentList && this.chooseStudentObj[key].studentList.forEach(item => {
            studentIdList.push(item.id)
          })
        }
      }
	  console.log(this.fileList)
      this.fileList.forEach(item => {
        fileList.push(item.src)
      })
      let params = this.paramsDetail
      Object.assign(params, {
        pubUserId: this.user.userId, // 3fa7f627dda84f3e914f79863e0eda0b
        pubUserType: 2,
        schoolId: this.user.locationId,
        studentIdList,
        fileList,
		fileDisplayNameList:[this.fileList&&this.fileList[0]?this.fileList[0].fileName:""]
      })
      Ajax.post("/commbook/title/publish", params).then(res => {
        this.snackbar = { isShow: true, content: res.message };
        this.$emit('onSave')
      })
    },
    onCancel(){
      this.$emit('onCancel')
    },
    onDelStudent(list, index){
      list.splice(index, 1)
    },
    queryTopicList(){
      Ajax.post(`/usermanage/setting/getList`, {
        dataLevel: 'commbook',
        dataType: 'topic',
        userId: this.user.userId
      }).then(res => {
        this.topicList = res.data || []
      })
    }
  },
  created() {},
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.recipients {
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}
.selected-student-list {
  padding: 20px 0px;
  p {
    display: inline-block;
    margin-right: 20px;
	margin-bottom: 8px;
    span {
      display: inline-block;
      padding: 4px 15px;
      padding-right: 8px;
      margin-right: 8px;
      border: 1px solid #E0E0E0;
      border-radius: 50px;
	  margin-bottom: 8px;
    }
  }
}
/deep/.el-upload-list.el-upload-list--text {
  display: none;
}
 /deep/.iconfont{
	 margin-right:5px;
	 vertical-align:-0.1em;
 }
 .previewImgBox{
	position: relative;
	width: 240px;
	.previewImg{
		position: absolute;
		    top: 10px;
		    right: 10px;
		    color: white;
		    background-color: rgba(0,0,0,0.6);
		    border-radius: 50%;
		    display: flex;
		    padding: 3px;
	}
 }
 /deep/.md-field label{
 font-family: Roboto;	
 font-size: 14px;
 }
/deep/.md-input{
	font-family: Roboto;
	font-size: 14px!important;
	&::placeholder {
		font-size: 14px;
		color: red;
	font-family: Roboto;	
	}
}
/deep/.contained{
	background-color: #1a73e8;
	color: #fff!important;
}
/deep/.contained:hover{
	background-color: #2b7de9!important;
}
/deep/.contained.disabled{
	background-color: #eee!important;
	    color: #b4b4b4!important;
}
 /deep/.md-field.md-has-value label{
 font-size: 12px;
 }
</style>