<!-- commbook -->
<template>
  <gg-Dialog
    :visible.sync="isShow"
    ref="resetPassword"
    :before-close="
      () => {
        $emit('cancel');
      }
    "
    @open="onOpenDialog"
  >
    <div slot="title">Select students</div>
    <div class="dialog-content">
      <baseGGSelect v-model="className" placeholder="Class">
    
		 <!-- v-if='studentListObj[key][0].id' -->
          <!-- <head-img
            :src="'item.photo'"
            line-height="26"
            @click="() => {}"
            style="display: inline-block; margin-right: 20px;"
          /> -->
        
		<EdoovoOption   v-for="(item, key) in studentListObj"
          :key="key"
          :value="key"
		 :label='studentListObj[key][0].className'>
			<span><head-img
						  class="user-box"
						  line-height="28"
						  :firstName="studentListObj[key][0].className"
							:lastName="`${studentListObj[key][0].className.split(' ').length>1?studentListObj[key][0].className.split(' ')[1]:''}`"
					
						  style="
						    display: inline-block;
						    margin-right: 10px;
						    vertical-align: middle;
						  "
						  :style="{ backgroundColor: item[0].themeColor }"
						></head-img> {{ studentListObj[key][0].className }}</span>
		</EdoovoOption>
      </baseGGSelect>
      <!-- <temperatureSelect class="float" /> -->
      <p style="width: 100%; padding: 10px 0px" class="name">SEARCH RESULTS</p>
      <div class="student-list-wrap">
        <p v-if="!currentStudentList.length">
          <i
            class="iconfont iconyiquxiao"
            style="vertical-align: middle; margin-right: 10px"
          ></i>
          No results
        </p>
        <ul v-else>
			<li>
				<baseCheckbox v-model="selectAll" class="selectCheckbox">
					<img
					  :src="require('@/assets/images/icons/select_all.svg')"
					  style="margin-right: 10px;width: 26px;height: 26px;"
					/>
					<div style="display: inline-block;" >
					  <p class="name">Select all</p>
					</div>
				</baseCheckbox>
			</li>
          <li v-for="(item, key) in currentStudentList" :key="key">
            <baseCheckbox v-model="item.isCheck" v-if='item.id'>
              <head-img
                :src="item.photo"
                line-height="26"
                :firstName="item.firstName"
                :lastName="item.lastName"
                @click="() => {}"
                style="display: inline-block; margin-right: 10px"
              />
              <div style="display: inline-block">
                <p class="name">{{ item.firstName }} {{ item.lastName }}</p>
                <p class="email">{{item.email||'-'}}</p>
              </div>
            </baseCheckbox>
          </li>
        </ul>
      </div>
      <p style="width: 100%; padding: 10px 0px">Selected students</p>
      <div class="selected-student-list">
        <p
          v-for="(item, key) in chooseStudentObj"
          :key="key"
          v-if="item.count && chooseStudentObj[key].studentList.length"
		  style="margin-bottom: 8px;"
        >
          <head-img
            class="user-box"
            line-height="28"
            :firstName="chooseStudentObj[key].studentList[0].className"
			:lastName="`${chooseStudentObj[key].studentList[0].className.split(' ').length>1?chooseStudentObj[key].studentList[0].className.split(' ')[1]:''}`"
            @click="() => {}"
            style="
              display: inline-block;
              margin-right: 10px;
              vertical-align: middle;
            "
            :style="{ backgroundColor: item.themeColor }"
          ></head-img>
          {{ item.count }} {{item.count==1?'student':'students'}}
        </p>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <gg-cancel-button @click="$emit('cancel')">Cancel</gg-cancel-button>
      <gg-submit-button @click="$emit('save', chooseStudentObj)"
        >Add</gg-submit-button
      >
    </span>
  </gg-Dialog>
</template>

<script>
// import temperatureSelect from "../modules/temperature-select.vue";
export default {
  components: {
    // temperatureSelect,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    studentListObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectedStudentObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      className: "",
      movie: 1,
      isShow: this.dialogVisible,
      currentStudentList: [],
      chooseStudentObj: {},
	  selectAll:false
    };
  },
  watch: {
	  selectAll(val){
		  let obj = this.studentListObj
		  for (let key in obj) {
		    obj[key] &&
		      obj[key].forEach((item) => {
				  if(item.classId==this.currentStudentList[0].classId && item.id){
					  item.isCheck=val
				  }
		      });
		  }
		  // this.chooseStudentObj = objTemp;
	  },
    dialogVisible(val) {
      this.isShow = val;
    },
    className(val) {
	this.onSelectClass(val);
    },
    studentListObj: {
      deep: true,
      handler(obj) {
        let objTemp = {};
        for (let key in obj) {
          objTemp[key] = {
            count: 0,
            studentList: [],
            themeColor: obj[key] && obj[key][0] && obj[key][0].themeColor,
          };
          obj[key] &&
            obj[key].forEach((item) => {
              if (item.isCheck) {
                objTemp[key].count++, objTemp[key].studentList.push(item);
              }
            });
        }
        this.chooseStudentObj = objTemp;
		console.log(this.chooseStudentObj)
      },
    },
  },
  methods: {
    onSelectClass(key) {
      this.currentStudentList = this.studentListObj[key] || [];
      this.currentStudentList.forEach((item) => {
        if (typeof item.isCheck === "undefined") {
          this.$set(item, "isCheck", false);
        }
      });
	  this.selectAll=false
    },
    onCancel() {
      this.$emit("onCancel");
    },
    onOpenDialog() {
      this.chooseStudentObj = this.selectedStudentObj;
    },
  },
  created() {},
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.dialog-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  max-height: 444px;
  overflow: auto;
  .text-reminder {
    margin-top: 20px;
    font-size: 12px;
    font-family: Roboto;
    color: #b4b4b4;
  }
  .float {
    width: calc(50% - 10px);
    flex-basis: calc(50% - 10px);
    flex-grow: 0;
    flex-shrink: 0;
  }
}
.student-list-wrap {
  width: 100%;
  height: 220px;
  overflow-y: auto;
  li {
    width: 100%;
    padding: 5px 0px;
    padding-left: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
      background-color: #eeeeee;
    }
  }
}
.selected-student-list {
  p {
    display: inline-block;
    margin-right: 40px;
  }
}
/deep/.table-cell-img {
  margin-right: 0px;
}
/deep/.el-checkbox__input {
  vertical-align: top;
  padding-top: 13px;
}
/deep/.selectCheckbox .el-checkbox__input{
	padding-top: 5px;
	
}
/deep/.user_avatar .textPhoto{
	font-size: 14px;
}
/deep/.base_checkbox{
	/* color: #448aff!important; */
	/* background-color: #448aff; */
}
/deep/.el-checkbox__inner{
	/* background-color: #448aff; */
}
.email{
	color: #202124;
	font-size: 12px;
	font-family: Roboto;
}
.name{
	color: #202124;
	font-family: Roboto-Medium;
		font-size: 14px;
}

/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
	
	/* color: black; */
	.email{
		
	}
}
/deep/.textPhoto{
	font-family: OpenSans-Bold!important;
}
</style>