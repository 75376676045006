<!-- commbook -->
<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb
      :data="[
        {
          routeName: 'locations',
          name: user.schoolName,
        },
        {
          name: 'Commbook',
        },
      ]"
      slot="breadcrumb"
    />
    <GgPage pageType="1" title="Commbook" class="commbook">
      <!-- content -->
      <template v-if="JSON.stringify(studentListObj) !== '{}'">
        <div
          class="commbook-header mar-b-20"
          v-show="!isShowRecipientsView"
          v-if="
            $buttonPermission('Messages', 'Full') ||
            $buttonPermission('Messages', 'Edit') ||
            $buttonPermission('Messages', 'Create')
          "
        >
		
          <head-img
            class="user-box"
            :src="user.userInfo.photo"
			:firstName='user.userInfo.firstName'
			:lastName='user.userInfo.lastName'
            line-height="36"
            @click="() => {}"
          ></head-img>
          <div style="cursor: pointer" @click="isShowRecipientsView = true">
            Start a conversation with your students ...
          </div>
        </div>
        <recipientsView
          isSelect
          v-if="isShowRecipientsView"
          :studentListObj="studentListObjTemp"
          @onCancel="isShowRecipientsView = false"
          @onSave="onRecipientsSave"
        />
        <section-list
          v-for="(item, key) in studentArr"
          isShowLastName
          :title="item[0].className"
          :data="item"
          :iconButton="false"
          class="mar-b-20"
          @handleClick="toStudentPage"
          :key="key"
        >
          <template slot="icon">
			   
            <head-img
              class="user-box"
              line-height="28"
              @click="() => {}"
              :firstName="item[0].className"
			 :lastName="`${item[0].className.split(' ').length>1 ?item[0].className.split(' ')[1]:''}`"
              style="margin-right: 10px"
              :style="{ backgroundColor: item[0] && item[0].themeColor }"
            ></head-img>
          </template>
        </section-list>
      </template>

      <edoovo-table
        v-if="JSON.stringify(studentListObj) === '{}'"
        height="100%"
        rowKey="id"
        defaultValue="-"
      >
        <!-- ===== -->
        <!-- 空状态 -->
        <!-- ===== -->
        <div slot="empty" style="text-align: center">
          <div
            style="
              width: 213px;
              text-align: center;
              border-radius: 50%;
              margin-bottom: 12px;
              line-height: 200px;
            "
          >
            <img
              style="width: 100%"
              :src="require('@/assets/images/products/noData.svg')"
            />
          </div>
          <p style="font-size: 22px">No message yet.</p>
        </div>
      </edoovo-table>
    </GgPage>
  </div>
</template>

<script>
import recipientsView from "./components/recipients";
import { Ajax } from "@/common";
import { mapState } from "vuex";
export default {
  components: {
    recipientsView,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      setNewMsgDataTimerId: null,
      isShowRecipientsView: false,
      studentListObj: {},
      studentListObjTemp: {},
	  studentArr:[]
    };
  },
  methods: {
    onRecipientsSave() {
      this.isShowRecipientsView = false;
      this.getData();
    },
    setNewMsgDataTimer() {
      this.setNewMsgDataTimerId = setTimeout(() => {
        this.getData();
        this.setNewMsgDataTimer();
      }, 60000);
    },
    getData(isFirst) {
      let params = {
        staffId: this.user.userId,
        locationId: this.user.locationId,
        userId: this.user.userId,
      };
      Ajax.get("/commbook/title/selectByStaffId", params).then((res) => {
        let studentListObj = res.data;
		let obj = studentListObj
		this.studentArr=[]
		
		for(var key in obj){　　//遍历对象的所有属性，包括原型链上的所有属性
		  if(obj.hasOwnProperty(key)){ //判断是否是对象自身的属性，而不包含继承自原型链上的属性
		  　      //键名
		  obj[key].forEach((item,index)=>{
			  if(!item.id){
				 //obj[key].splice(index,1) 
			  }
		  })
		  this.studentArr.push(obj[key])
		　}
		}
        this.studentListObj = studentListObj;
        if (isFirst) {
          this.studentListObjTemp = studentListObj;
        }
      });
    },
    toStudentPage(row) {
      this.$router.push({
        name: "commbookStudent",
        query: { studentId: row.id, firstName: row.firstName ,lastName:row.lastName},
      });
    },
  },
  created() {
    this.getData(true);
    this.setNewMsgDataTimer();
  },
  beforeDestroy() {
    clearTimeout(this.setNewMsgDataTimerId);
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.commbook {
  &-header {
    display: flex;
    align-items: center;
    padding: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    font-size: 14px;
    color: rgba(32, 33, 36, 0.5);
    font-family: Roboto;
    border-radius: 13px;
    .user-box {
      margin-right: 28px;
    }
  }
}
.sectionIcon {
  width: 32px;
  height: 32px;
}
/deep/.section-list-main .item {
  cursor: pointer;
}

/deep/.user_avatar .textPhoto{
	font-size: 14px;
}
</style>