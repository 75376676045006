<template>
  <div class="specific-reply-input-wrap" :class="{ isFocusBorder: dataItem.isFocus }" v-loading='loading' >
    <input type="text"
      v-model="dataItem._repleyValue"
      @focus="$set(dataItem, 'isFocus', true)"
      @blur="onReplayInputBlur(item)"
    >
    <div style="padding-top: 10px;" v-if="dataItem.fileListTemp && dataItem.fileListTemp.length">
      <imageListPreview :fileList="dataItem.fileListTemp || []" @loading='changeloading' />
	  
    </div>
<!-- 	<div v-if="dataItem.fileListTemp && dataItem.fileListTemp.length"  class="previewImgBox" style="padding-top: 10px;">
		 
		  		  <div  class="previewImg">
		  			   <i class="el-icon-close" style="font-size: 15px; cursor: pointer;" @click="dataItem.fileListTemp=[]"></i>
		  		  </div>
		  		    <img :src="dataItem.fileListTemp[0].src||''" style='width: 240px;' alt="">
		  </div> -->
    <div style="text-align: right;" >
		
      <el-upload
        style="display: inline-block;"
        :auto-upload="false"
        action=""
        accept="image/jpeg,image/png,application/pdf"
        :show-file-list="false"
        :on-change="(file, fileList) => {onUploadFileChange(file, fileList)}"
		:before-upload="beforeUpload"
      >
		<span 	v-if="dataItem._repleyValue && dataItem.fileListTemp.length==0" style="color: #448aff;line-height: 32px; cursor: pointer; vertical-align: middle;font-size: 18px; margin-right: 6px;" class="icon iconfont iconattach_file-web"></span>
      </el-upload>
      <i
        class="iconfont iconsend"
        :class="{ isFocus: dataItem._repleyValue || dataItem.fileListTemp}"
        style="line-height: 32px; cursor: pointer; vertical-align: middle; display: inline-block;"
        @click="onReply({ value: dataItem._repleyValue })"
      >
      </i>
    </div>
  </div>
</template>

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
import imageListPreview from '@/components/private-components/ImageListPreview/index1.vue'
export default {
  components: {
    imageListPreview
  },
  props: {
    dataItem: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      item: {
        fileListTemp: []
      },
	  loading:false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
  },
  mounted(){
  },
  methods: {
	  changeloading(){
	  	this.loading=false 
		this.$emit('loading')
	  },
	  beforeUpload(file) { 			
	  			const isLt2M = file.size / 1024 / 1024 < 25     //这里做文件大小限制
	  			if(!isLt2M) {
	  			this.$message({
	  			message: 'Upload files should not exceed 25MB',
	  			type: 'warning'
	  			});
	  			}
	  			return  isLt2M
	  			},
	  removeFileList(){
	  		this.dataItem.fileListTemp=[]
	  },
    onUploadFileChange(file, fileList){
		 const isLt2M = file.size / 1024 / 1024 < 25     //这里做文件大小限制
				   console.log(isLt2M)
				  if(!isLt2M) {
				  	this.$message({
				  		message: 'Upload files should not exceed 25MB',
				  		type: 'warning'
				  	});
				  	return  isLt2M
				  }
				  this.loading=true
      if (!this.dataItem.fileListTemp) this.$set(this.dataItem, 'fileListTemp', [])
      if (file) {
        let fileName = file.name
        let formData = new FormData();
        formData.append("file", file);
        formData.append("multipartFile", file.raw);
        Ajax.post(
          // "/media/public/file/upload",
          "/media/public/file/uploadExt",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((res) => {
          if (res.code === "0000") {
            let imgSrc = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
            this.dataItem.fileListTemp.push({
              fileName,
              src: imgSrc,
              id: res.data.requestId
            })
			
          }
        })
        .catch((err) => {
          console.log(err)
        });
      }
    },
    onReply(targetObj){
      this.$emit('onReply', targetObj, this.dataItem.id, this.dataItem.pubUserId, this.dataItem)
    },
    onReplayInputBlur(item){
      setTimeout(() => {this.$set(this.dataItem, 'isFocus', false)}, 300)
    },
  },
  created() {},
};
</script>
<style lang='less' scoped>
.specific-reply-input-wrap {
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  input {
    width: 100%;
    border: 0;
    &:hover, &:focus {
      outline: 0;
    }
  }
  .isFocus {
    color: #448aff;
  }
  &.isFocusBorder {
    border: 1px solid #448aff;
  }
}
 .previewImgBox{
	position: relative;
	width: 240px;
	.previewImg{
		position: absolute;
		    top: 20px;
		    right: 10px;
		    color: white;
		    background-color: rgba(0,0,0,0.6);
		    border-radius: 50%;
		    display: flex;
		    padding: 3px;
	}
 }
</style>